import { useTheme } from '@emotion/react';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Alert, Box, Loader, Modal, Text } from '~/components/blocks';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';

import { ConfirmContent } from './ConfirmContent';
import { useRequestUberDelivery } from './ConfirmContent/use-request_uber_delivery';
import { PiecesContentForm } from './PiecesContentForm';
import { SelectQuantityContent } from './SelectQuantityContent';
import { Fields, UberDeliveryErrorCode } from './type';
import { useFetchUberQuote } from './use_fetch_uber_quote';
import { validationSchema } from './validation';

export const RequestUberDeliveryModal = () => {
  const theme = useTheme();
  const { error, graphqlError, isOpen, quoteId, step } = useRecoilValue(
    requestUberDeliveryModalState,
  );
  const resetState = useResetRecoilState(requestUberDeliveryModalState);
  const { loading } = useFetchUberQuote();
  const initialValues = {
    quantity: 1,
    pieces: [1] as Array<number | ''>,
  };
  const { create, loading: requesting, error: requestError, resetError } = useRequestUberDelivery();

  const handleSubmit = useCallback(
    async (values: Fields) => {
      try {
        await create(values.pieces);
      } catch {
        // 何もしない
      }
    },
    [create],
  );
  const handleClose = useCallback(() => {
    resetState();

    if (requestError) {
      resetError();
    }
  }, [requestError, resetError, resetState]);

  return (
    <Modal open={isOpen} size="m" onClose={handleClose}>
      <Modal.Header>{step === 'confirm' ? '集荷依頼確認' : '集荷依頼'}</Modal.Header>
      {error && (
        <Alert
          status="error"
          whiteSpace="pre-wrap"
          marginBottom={theme.space.xl}
          marginTop={theme.space.m}
        >
          {error === UberDeliveryErrorCode.addressUndeliverableLimitedCouriers ||
          error === UberDeliveryErrorCode.couriersBusy ? (
            <Text>
              <b>近くに配達員が見つかりません。しばらく時間を置いて再度試してみてください</b>
              <br />
              受け渡し方法を変更するとより早くお薬をお渡しできるかもしれません。患者にご相談ください
            </Text>
          ) : (
            <Text>
              予期せぬエラーが発生したため、当日配達を利用できません。患者にご相談の上、受け渡し方法を変更してください
            </Text>
          )}
        </Alert>
      )}
      {graphqlError && (
        <Alert
          status="error"
          whiteSpace="pre-wrap"
          marginBottom={theme.space.xl}
          marginTop={theme.space.m}
        >
          <Text>{graphqlError}</Text>
        </Alert>
      )}
      {requestError && (
        <Alert status="error" whiteSpace="pre-wrap" marginTop={theme.space.m}>
          {requestError}
        </Alert>
      )}
      {(loading && !error && !graphqlError) || (requesting && !requestError) ? (
        <Modal.Body>
          <Box position="relative" height="480px">
            <Loader open inside />
          </Box>
        </Modal.Body>
      ) : quoteId && !error && !graphqlError ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <>
              {step === 'selectQuantity' ? <SelectQuantityContent formik={formik} /> : null}
              {step === 'inputPieces' ? <PiecesContentForm formik={formik} /> : null}
              {step === 'confirm' ? <ConfirmContent formik={formik} loading={requesting} /> : null}
            </>
          )}
        </Formik>
      ) : null}
    </Modal>
  );
};
